import { defineStore } from 'pinia';
import { updateUrlParameter } from '@/shared/utils/urls';
import { useProductStore } from '@/shared/pinia/modules/product.js';
import { breakpoints } from '@/shared/constants/breakpoints';
import { ref, computed } from 'vue';

const blocksNumber = {
  ['productpage']: {
    smScreen: 4,
    mdScreen: 5,
    lgScreen: 6,
    xlScreen: 7
  }
};
const lowInStockThreshold = 10;

export const useProductOptionsStore = defineStore('product-options', () => {
  const usingDefaultOption = ref(false);
  const activeOption = ref(null);
  const options = ref([]);
  const kind = ref(null);
  const showOptionsModal = ref(false);
  const modalOptionsPosition = ref(0);
  const haveDifferentPrices = ref(false);

  const targetVariant = computed(() => {
    return (
      activeOption.value ??
      options.value.find(
        (variant) => variant.id === window.Hobbii?.productPage?.targetVariantId
      ) ??
      options.value[0]
    );
  });

  const hasOptions = computed(() => options.value.length > 0 && !!kind.value);

  const productInStock = computed(() => {
    if (activeOption.value?.id) {
      return activeOption.value.inStock;
    } else {
      return optionsInStock.value && optionsInStock.value.length != 0;
    }
  });

  const optionsInStock = computed(() =>
    options.value.filter((option) => option.inStock)
  );

  const optionsOutOfStock = computed(() =>
    options.value.filter((option) => !option.inStock)
  );

  const blocksNumberCalculation = (screenSize) => {
    const productStore = useProductStore();

    let blkNum = blocksNumber[productStore.strategy].smScreen;
    if (screenSize >= breakpoints.md && screenSize < breakpoints.lg) {
      blkNum = blocksNumber[productStore.strategy].mdScreen;
    } else if (screenSize >= breakpoints.lg && screenSize < breakpoints.xl) {
      blkNum = blocksNumber[productStore.strategy].lgScreen;
    } else if (screenSize >= breakpoints.xl) {
      blkNum = blocksNumber[productStore.strategy].xlScreen;
    }

    return blkNum;
  };

  const isOptionSelected = computed(
    () => (hasOptions.value && !!activeOption.value?.id) || !hasOptions.value
  );

  const updatePhotoGallery = (option) => {
    window.dispatchEvent(
      new CustomEvent('changed.hobbii.product.image', {
        detail: {
          imageUrl: option.consolidatedImageUrl
        }
      })
    );
  };

  const updateActiveOption = (option) => {
    const productStore = useProductStore();

    updateUrlParameter('variant', option.id);
    activeOption.value = option;
    updatePageTitle(option.title);
    updatePhotoGallery(option);

    if (haveDifferentPrices.value) {
      productStore.updatePriceByVariant(option);
    }

    // TODO update stock in physical stores setAvailableInStores()
    // TODO update datalayer
    // TODO update stock
  };

  return {
    usingDefaultOption,
    activeOption,
    lowInStockThreshold,
    options,
    kind,
    showOptionsModal,
    modalOptionsPosition,
    haveDifferentPrices,
    targetVariant,
    productInStock,
    optionsInStock,
    optionsOutOfStock,
    blocksNumberCalculation,
    isOptionSelected,
    updateActiveOption
  };
});

const updatePageTitle = (optionTitle) => {
  const separator = ' - ';

  const titleParts = document.title.split(separator);
  const replaceName = titleParts.length === 3;
  titleParts.splice(1, replaceName ? 1 : 0, optionTitle);

  document.title = titleParts.join(separator);
};
