import { defineStore } from 'pinia';
import { mutateAcceptToc } from '../../graphql/gateway/mutations';

export const useUserStore = defineStore('user', {
  state: () => ({
    customer: null,
    tocStatus: false,
    showTocAgree: false,
    ownProfile: null,
    followers_count: 0,
    following_count: 0
  }),
  getters: {
    isLogged() {
      return !!this.customer;
    }
  },
  actions: {
    acceptToc({ onSuccess, onError }) {
      this.apolloClient
        .mutate(mutateAcceptToc('community'))
        .then(({ data }) => {
          if (data.toc.accept.success) {
            this.tocStatus = true;
            return onSuccess();
          }
          onError();
        })
        .catch((e) => {
          console.log('Failed to accept ToC', e);
          onError();
        });
    }
  }
});
